<template>

  <div>
    <b>Вход через Сервис аутентификации</b>
    <el-form
      class="login-form"
      :model="model"
      :rules="rules"
      ref="form"
      @submit.native.prevent="login"
    >
      <el-form-item prop="username">
        <el-input v-model="model.login" placeholder="Логин" prefix-icon="fas fa-user"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="model.password"
          placeholder="Пароль"
          type="password"
          prefix-icon="fas fa-lock"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          class="login-button"
          type="primary"
          native-type="submit"
          block
        >Войти
        </el-button>
      </el-form-item>
    </el-form>

<!--    <router-link-->
<!--      :to="{ name: 'Restore'}"-->
<!--    >-->
<!--      Забыли пароль?-->
<!--    </router-link>-->

  </div>
</template>

<script>


import requestSender from "@/api/base/requestSender";

export default {
  name: "login-pass",

  props: {
    // userId: {type: Number}
  },

  data() {
    return {
      loading: false,

      model: {
        login: '',
        password: '',
      },
      rules: {},
    }
  },

  methods: {
    login() {
      this.loading = true;
      requestSender('post', 'auth/open-id/login', {
        login: this.model.login,
        password: this.model.password,
      })
        .then(data => {
          localStorage.setItem('jwt', data.token);
          localStorage.setItem('refresh_token', data.refresh_token);
          localStorage.setItem('last_login_method', 'loginPass');

          this.$router.push({name: 'Home'});
        })
        .finally(() => {
          this.loading = false;
        })
    },

  }

}
</script>

<style>

</style>